import {
  defineStyle,
  defineStyleConfig,
  extendTheme,
  type ThemeConfig,
} from '@chakra-ui/react';
import { Roboto } from 'next/font/google';

const roboto = Roboto({
  weight: ['100', '300', '400', '500', '700', '900'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
});

const colors = {
  red: {
    900: '#410B0D',
    800: '#570F11',
    700: '#821719',
    600: '#AD1F22',
    500: '#D9262A',
    400: '#E05255',
    300: '#E87D7F',
    200: '#F0A8AA',
    100: '#F7D4D4',
    50: '#FBE9EA',
  },
  orange: {
    900: '#492404',
    800: '#613005',
    700: '#914808',
    600: '#C2600A',
    500: '#F2780D',
    400: '#F5933D',
    300: '#F7AE6E',
    200: '#FAC99E',
    100: '#FCE4CF',
    50: '#FEF1E7',
  },
  yellow: {
    900: '#4D3800',
    800: '#664B00',
    700: '#997000',
    600: '#CC9600',
    500: '#FFBB00',
    400: '#FFC933',
    300: '#FFD666',
    200: '#FFE499',
    100: '#FFF1CC',
    50: '#FFF8E5',
  },
  green: {
    900: '#133919',
    800: '#194D22',
    700: '#267333',
    600: '#349A45',
    500: '#40BF55',
    400: '#66CC77',
    300: '#8CD999',
    200: '#B3E5BB',
    100: '#D9F2DD',
    50: '#ECF9EE',
  },
  teal: {
    900: '#084540',
    800: '#0A5C55',
    700: '#0F8A7F',
    600: '#14B8AA',
    500: '#19E4D3',
    400: '#47EBDD',
    300: '#75F0E5',
    200: '#A3F5EE',
    100: '#D1FAF7',
    50: '#E8FCFB',
  },
  cyan: {
    900: '#00334D',
    800: '#004466',
    700: '#006699',
    600: '#0088CC',
    500: '#00A9FD',
    400: '#33BBFF',
    300: '#66CCFF',
    200: '#99DDFF',
    100: '#CCEEFF',
    50: '#E5F6FF',
  },
  blue: {
    900: '#002133',
    800: '#003756',
    700: '#004D78',
    600: '#00639A',
    500: '#006EAB',
    400: '#1A7DB3',
    300: '#4D9AC4',
    200: '#80B7D5',
    100: '#B3D4E6',
    50: '#E6F1F7',
  },
  purple: {
    900: '#210845',
    800: '#2C0A5C',
    700: '#420F8A',
    600: '#5814B8',
    500: '#6E19E4',
    400: '#8B47EB',
    300: '#A875F0',
    200: '#C5A3F5',
    100: '#E2D1FA',
    50: '#F1E8FC',
  },
  pink: {
    900: '#3D0F2E',
    800: '#52143D',
    700: '#7A1F5C',
    600: '#A3297A',
    500: '#CA3398',
    400: '#D65CAD',
    300: '#E085C2',
    200: '#EBADD6',
    100: '#F5D6EB',
    50: '#FAEBF5',
  },
  gray: {
    900: '#262626',
    800: '#333333',
    700: '#4D4D4D',
    600: '#666666',
    500: '#808080',
    400: '#999999',
    300: '#B3B3B3',
    200: '#CCCCCC',
    100: '#E6E6E6',
    50: '#F2F2F2',
  },
};

const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: '500',
  },
  variants: {
    solid: {
      bg: '#006EAB',
      color: '#ffff',
      fontWeight: '500',
      _hover: {
        bg: '#0681BF',
        _disabled: {
          bg: '#b3b3b3',
        },
      },
      _active: {
        bg: '#005089',
      },
      _disabled: {
        bg: '#B3B3B3',
        cursor: 'not-allowed',
      },
    },
    secondary: {
      bg: '#E6F1F7',
      color: '#006EAB',
      fontWeight: '500',
      _hover: {
        bg: '#B3D4E6',
        _disabled: {
          bg: '#b3b3b3',
        },
      },
      _active: {
        bg: '#ffffff',
      },
      _disabled: {
        bg: '#B3B3B3',
        cursor: 'not-allowed',
      },
    },
    outline: (props) => ({
      bg: 'transparent',
      color: props.colorMode === 'light' ? '#005089' : '#ffff',
      border: '1px',
      borderColor: props.colorMode === 'light' ? '#005089' : '#81D1EE',
      _hover: {
        bg: 'transparent',
        color: props.colorMode === 'light' ? '#0681BF' : '#81D1EE',
        borderColor: props.colorMode === 'light' ? '#0681BF' : '#81D1EE',
        _disabled: {
          bg: 'transparent',
          borderColor: '#B3B3B3',
          color: '#B3B3B3',
        },
      },
      _active: {
        bg: props.colorMode === 'light' ? '#E1F4FB' : '#FFFFFF29',
        color: props.colorMode === 'light' ? '#005089' : '#ffff',
        borderColor: props.colorMode === 'light' ? '#005089' : '#81D1EE',
      },
      _disabled: {
        bg: 'transparent',
        borderColor: '#B3B3B3',
        color: '#B3B3B3',
        cursor: 'not-allowed',
      },
    }),

    ghost: (props) => ({
      bg: 'transparent',
      color: props.colorMode === 'light' ? '#005089' : '#ffff',
      _hover: {
        color: props.colorMode === 'light' ? '#0681BF' : '#81D1EE',
        bg: 'transparent',
        _disabled: {
          bg: 'transparent',
          color: '#B3B3B3',
        },
      },
      _active: {
        bg: props.colorMode === 'light' ? '#E1F4FB' : '#FFFFFF29',
        color: props.colorMode === 'light' ? '#005089' : '#ffff',
      },
      _disabled: {
        bg: 'transparent',
        color: '#B3B3B3',
        cursor: 'not-allowed',
      },
    }),

    danger: {
      bg: '#D9262A',
      color: '#ffff',
      fontWeight: '500',
      _hover: {
        bg: '#a02426',
        _disabled: {
          bg: '#b3b3b3',
        },
      },
      _active: {
        bg: '#721617',
      },
      _disabled: {
        bg: '#B3B3B3',
        cursor: 'not-allowed',
      },
    },
  },
});

const brandWhite = defineStyle({
  background: 'white',
  color: 'blue.500',
  fontWeight: 'medium',
  textTransform: 'capitalize',
  rounded: 'full',
});

export const badgeTheme = defineStyleConfig({
  variants: { brandWhite },
});

export const theme = extendTheme({
  colors,
  fonts: {
    body: 'inherit',
    heading: 'inherit',
  },
  config: {
    initialColorMode: 'system',
  },
  styles: {
    global: {
      ':root': {
        fontFamily: roboto.style.fontFamily,
      },
      body: {
        fontFamily: roboto.style.fontFamily,
        fontFeatureSettings: '"cv05" on, "cv08" on;',
        overflowY: 'scroll',
      },
      mark: {
        backgroundColor: '#D4DAF7',
        color: 'gray.900',
        fontWeight: 'bold',
      },
      '.z-50': {
        zIndex: '5000 !important',
      },
      '.react-datepicker-wrapper': {
        width: '100%',
      },
      '.chakra-react-select__menu-portal': {
        zIndex: 100,
      },
    },
  },
  components: {
    Button,
    Badge: badgeTheme,
  },
} as ThemeConfig);
